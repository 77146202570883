import React, { useState, useEffect } from "react";
import api from "../api/api";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";
import DashboardCard from "../components/DashboardCard";
import Graph from "../cards/Graph";
import DropDown from "../components/DropDown";
import DailyQuestions from "../cards/DailyQuestions";
import Quotes from "../cards/Quotes";
import BibleQuotes from "../cards/BibleQuotes";
import Users from "../cards/Users";

const date = new Date();

export default function Home({ handleSidebarOpen }) {
  const [name, setName] = useState("Micah");
  const [loading, setLoading] = useState(true);
  const [graphYear, setGraphYear] = useState(new Date().getFullYear());
  const [graphMonth, setGraphMonth] = useState(
    `${date.getFullYear()}-${date.getMonth() + 1}-1`
  );
  const [cardData, setCardData] = useState({
    totalUsers: "7M",
    todayUsers: "7M",
    subscription: "7M",
    user: "7M",
    lastMonthSubscriptions: 1,
    thisMonthSubscriptions: 1,
    percentage: 1,
    type: "up",
  });

  const [graphData, setGraphData] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: {
      subscriptions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      users: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  });

  const months = [
    {
      text: "This Month",
      value: `${date.getFullYear()}-${date.getMonth() + 1}-1`,
    },
    {
      text: "Last Month",
      value:
        date.getMonth() === 0
          ? `${date.getFullYear() - 1}-12-1`
          : `${date.getFullYear()}-${date.getMonth()}-1`,
    },
    {
      text: "Last 90 days",
      value:
        date.getMonth() < 3
          ? `${date.getFullYear() - 1}-${9 + date.getMonth()}-1`
          : `${date.getFullYear()}-${date.getMonth() - 2}-1`,
    },
  ];

  const nFormatter = (num) => {
    if (num >= 10e9) {
      return (num / 10e9).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 10e6) {
      return (num / 10e6).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 10e3) {
      return (num / 10e3).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  };

  const handleMonthChange = (e) => {
    setGraphMonth(e.target.value);
  };

  const handleGraphYearChange = (year) => {
    setGraphYear(parseInt(year));
  };

  useEffect(() => {
    const getCardData = async () => {
      const data = await api.getDashboardData({
        graphYear: graphYear,
        graphMonth: graphMonth,
      });
      setCardData({
        totalUsers: nFormatter(data.allUsers),
        todayUsers: nFormatter(data.todayUsers),
        subscription: nFormatter(data.thisMonthSubscriptions),
        user: nFormatter(cardData.user),
        lastMonthSubscriptions: data.lastMonthSubscriptions,
        thisMonthSubscriptions: data.thisMonthSubscriptions,
        percentage:
          ((data.thisMonthSubscriptions - data.lastMonthSubscriptions) /
            data.lastMonthSubscriptions) *
            100 <
          0
            ? -(
                ((data.thisMonthSubscriptions - data.lastMonthSubscriptions) /
                  (data.lastMonthSubscriptions || 1)) *
                100
              )
            : ((data.thisMonthSubscriptions - data.lastMonthSubscriptions) /
                (data.lastMonthSubscriptions || 1)) *
              100,
        type:
          ((cardData.thisMonthSubscriptions - cardData.lastMonthSubscriptions) /
            cardData.lastMonthSubscriptions) *
            100 <
          0
            ? "down"
            : "up",
      });
      graphData.datasets.subscriptions = data.yearSubCount;
      graphData.datasets.users = data.yearUserCount;
      setGraphData({ ...graphData });
    };

    getCardData();

    const user = JSON.parse(localStorage.getItem("user"));
    setName(user.name);
  }, [graphYear, graphMonth]);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.greetingsHeader}>
          <div className={styles.leftSection}>
            <span className={styles.greetings}>Welcome back, {name}</span>
            <span className={styles.screenName}>Dashboard</span>
          </div>
          <div className={styles.rightSection}>
            <DropDown options={months} action={handleMonthChange} />
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <DashboardCard
            title="Total Users"
            value={cardData.totalUsers}
            type="up"
            percentage="1%"
          />
          <DashboardCard
            title="Today Users"
            value={cardData.todayUsers}
            type="down"
            percentage="1%"
          />
          <DashboardCard
            title="Subscription"
            value={cardData.subscription}
            type={
              ((cardData.thisMonthSubscriptions -
                cardData.lastMonthSubscriptions) /
                cardData.lastMonthSubscriptions) *
                100 <
              0
                ? "down"
                : "up"
            }
            percentage={cardData.percentage.toFixed(2) + "%"}
          />
          <DashboardCard
            title="User"
            value={cardData.user}
            type="up"
            percentage="1%"
          />
        </div>
        <div className={styles.graphContainer}>
          <Graph
            title="Stats"
            graphData={graphData}
            handleGraphYearChange={handleGraphYearChange}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <DailyQuestions />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <Quotes />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <BibleQuotes />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <Users />
        </div>
      </div>
    </div>
  );
}
