import React, { useState, useEffect } from "react";
import styles from "../styles/components/pagination.module.scss";

export default function Pagination({
  tableOptions,
  countTotal,
  setTableOptions,
}) {
  const [prevPageActive, setPrevPageActive] = useState(false);
  const [nextPageActive, setNextPageActive] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const handlePageChange = (page) => {
    tableOptions.page = page;
    setTableOptions({ ...tableOptions });
  };
  const handleNextBtn = () => {
    const pc = Math.ceil(countTotal / tableOptions.limit);
    if (tableOptions.page < pc) {
      tableOptions.page++;
      setTableOptions({ ...tableOptions });
    }
  };
  const handlePrevBtn = () => {
    if (tableOptions.page > 1) {
      tableOptions.page--;
      setTableOptions({ ...tableOptions });
    }
  };

  useEffect(() => {
    console.log("countTotal", countTotal);
    const pc = Math.ceil(countTotal / tableOptions.limit);
    if (tableOptions.page < pc) {
      setNextPageActive(true);
    } else {
      setNextPageActive(false);
    }
    if (tableOptions.page > 1) {
      setPrevPageActive(true);
    } else {
      setPrevPageActive(false);
    }
    setPageCount(pc);
  }, [countTotal, tableOptions]);

  return (
    <div className={styles.paginationContainer}>
      <button
        className={styles.prevBtn}
        onClick={handlePrevBtn}
        disabled={!prevPageActive}
      >
        Prev
      </button>
      {Array.apply(null, { length: pageCount }).map((x, index) => {
        return (
          <button
            key={index}
            className={
              tableOptions.page === index + 1 ? styles.activeBtn : null
            }
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        );
      })}

      <button
        className={styles.nextBtn}
        onClick={handleNextBtn}
        disabled={!nextPageActive}
      >
        Next
      </button>
    </div>
  );
}
