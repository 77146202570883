import config from "../config/config";
import axios from "axios";

const getToken = () => {
    let token = localStorage.getItem("token");
    if (!token) {
        token = sessionStorage.getItem("token");
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const dropOffEmailsApi = {
    sendEmail: (data) => {
        getToken();
        return new Promise(async (resolve) => {
            let response = await axios.post(`${config.serverUrl}users/admin/drop-off-email`, data);
            resolve(response.data);
        });
    }
}

export default dropOffEmailsApi;