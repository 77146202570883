import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import CardOptions from "../components/CardOptions";
import Table from "../components/Table";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";

import dailyQuestions from "../api/dailyQuestions";
import LoadingAnim from "../components/LoadingAnim";

export default function DailyQuestion() {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [notification, setNotification] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    date: null,
  });
  const [tableData, setTableData] = useState({
    th: ["No.", "Question", "Date", "Edit"],
    td: [],
  });

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these questions?"))
      return;
    let checked = tableData?.td.filter((td) => td.checked);

    let res = await dailyQuestions.deleteBulk(checked);
    if (res.message.includes("Deleted")) {
      setNotification({
        type: "success",
        text: "Question deleted successfully",
      });
      setEditRow(null);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const handleEditDateChange = (e) => {
    if (!editRow) return;
    editRow.date = e.target.value;
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let data = {
      date: new Date(editRow.date).toISOString(),
      text: editRow.question,
      id: editRow.id,
    };
    let res = await dailyQuestions.updateQuestion(data);
    if (res._id) {
      setNotification({
        type: "success",
        text: "Question updated successfully",
      });
      setEditRow(null);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this question?") === true
    ) {
      setLoading(true);
      let res = await dailyQuestions.deleteQuestion({ id: editRow.id });
      if (res.message.includes("Deleted")) {
        setNotification({
          type: "success",
          text: "Question deleted successfully",
        });
        setEditRow(null);
        setUpdateTrigger(!updateTrigger);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setEditRow(null);
      setLoading(false);
    }
  };

  const handleEditText = (e) => {
    if (!editRow) return;
    editRow.question = e.target.value;
    console.log(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    const getTableData = async () => {
      const data = await dailyQuestions.getQuestions(tableOptions);
      setLoading(false);
      setCountTotal(data.countTotal);

      tableData.td = [];
      data.questions.map((question, index) => {
        tableData.td.push({
          id: question._id,
          checked: false,
          question: question.text,
          date: new Date(question.date).toLocaleDateString(),
          button: { action: buttonAction, text: "Edit" },
        });
      });
      setTableData({ ...tableData });
    };
    getTableData();
  }, [tableOptions, date, updateTrigger]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Daily Question</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading}/>
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={<Filters handleDateChange={handleEditDateChange} />}
            handleEditText={handleEditText}
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}

export function Filters({ handleDateChange }) {
  return (
    <>
      <DatePicker action={handleDateChange} />
    </>
  );
}
