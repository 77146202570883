import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import Table from "../components/Table";
import DatePicker from "../components/DatePicker";
import quotes from "../api/quotes";
import Pagination from "../components/Pagination";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";

import userIcon from "../assets/images/userIcon.svg";

export default function FilterQuotes({ updateTrigger, setUpdateTrigger }) {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [notification, setNotification] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
  });

  const [tableData, setTableData] = useState({
    th: ["No.", "Quotes", "Date", "Edit"],
    td: [],
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    let checked = tableData?.td.filter((td) => td.checked);
    setLoading(true);
    let res = await quotes.deleteBulk(checked);
    if (res.message.includes("Deleted")) {
      setNotification({
        type: "success",
        text: "Question deleted successfully",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];

  const buttonAction = (data) => {
    console.log(data);
  };
  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const handleEditDateChange = (e) => {
    if (!editRow) return;
    editRow.date = e.target.value;
  };
  const handleAuthorChange = (e) => {
    if (!editRow) return;
    editRow.author = e.target.value;
  };

  const handleEditText = (e) => {
    if (!editRow) return;
    editRow.quote = e.target.value;
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    const currDate = new Date(editRow.date);
    let day = currDate.getDate();
    let month = currDate.getMonth() + 1;
    const year = currDate.getFullYear();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;

    let formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;

    if (editRow.date?.includes("/")) {
      formattedDate = `${year}-${day}-${month}T00:00:00.000Z`;
    }
    
    let data = {
      date: new Date(formattedDate),
      text: editRow.quote,
      id: editRow.id,
      author: editRow.author,
    };
    let res = await quotes.updateQuote(data);
    if (res._id) {
      setNotification({
        type: "success",
        text: "Question updated successfully",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this quote?") === true
    ) {
      setLoading(true);
      let res = await quotes.deleteQuote({ id: editRow.id });
      if (res.message.includes("Deleted")) {
        setNotification({
          type: "success",
          text: "Quote deleted successfully",
        });
        setEditRow(null);
        setLoading(false);
        setUpdateTrigger(!updateTrigger);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
    }
  };

  useEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      const data = await quotes.getQuotes(tableOptions);
      setCountTotal(data.countTotal);

      tableData.td = [];
      data.quote.map((quote, index) => {
        tableData.td.push({
          id: quote._id,
          checked: false,
          quote: quote.text,
          date: quote.date ? new Date(quote.date).toDateString() : "-",
          button: { action: buttonAction, text: "Edit" },
          author: quote.author,
        });
      });
      setLoading(false);
      setTableData({ ...tableData });
    };
    setLoading(false);
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    console.log(editRow, "editRow");
  }, [editRow]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Filter</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleDateChange={handleEditDateChange}
                editRow={editRow}
                handleAuthorChange={handleAuthorChange}
              />
            }
            handleEditText={handleEditText}
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ handleDateChange, editRow, handleAuthorChange }) {
  return (
    <>
      <DatePicker action={handleDateChange} defaultValue={editRow?.date} />
      <ShortInput
        icon={userIcon}
        action={handleAuthorChange}
        placeholder="Author"
        defaultValue={editRow?.author}
      />
    </>
  );
}
