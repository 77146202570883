import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";

import AddBibleQuote from "../cards/AddBibleQuote";
import BulkUpload from "../cards/BulkUpload";
import FilterBible from "../cards/FilterBible";
import bibleVerse from "../api/bibleVerse";
import Notification from "../components/Notification";

export default function BibleScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const validation = ["date", "bibleQuote", "bibleBook", "chapter", "verse"];

  const handleUpload = async (data) => {
    try {
      setLoading(true);
      // console.log("BULK UPLOAD DATA ==> ", data)

      let formattedArray = data.map((item) => {
        item.text = item.bibleQuote;
        return { ...item, date: item.date };
      });

      console.log("FORMATTED ARRAY ==> ", formattedArray);

      let res = await bibleVerse.bulkUpload(formattedArray);
      if (Array.isArray(res) && res.length > 0) {
        setNotification({
          type: "success",
          text: "Bible Quotes uploaded successfully",
        });
        setUpdateTrigger(!updateTrigger);
        setLoading(false);
      } else {
        setNotification({
          type: "error",
          text: "Something went wrong",
        });
        setLoading(false);
      }
    } catch (error) {
      console.log("ERROR ==> ", error);
    }
  };

  return (
    <div className={styles.homeContainer}>
      <Notification data={notification} />
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <AddBibleQuote
            setUpdateTrigger={setUpdateTrigger}
            updateTrigger={updateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <BulkUpload
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            validation={validation}
            handleUpload={handleUpload}
            loading={loading}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <FilterBible
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
