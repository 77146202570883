import React from "react";
import styles from "../styles/components/shortInput.module.scss";

export default function ShortInput({
  defaultValue,
  placeholder,
  action,
  icon,
  type,
  ...props
}) {
  return (
    <div className={styles.shortInputWrapper}>
      {icon && <img src={icon} alt="" />}
      <input
        type={type || "text"}
        onChange={action}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...props}
      />
    </div>
  );
}
