import React, { useEffect, useState, useRef } from "react";
import styles from "../styles/components/imageTable.module.scss";
import Checkbox from "react-custom-checkbox";

import Button from "./Button";
import SmallButton from "./SmallButton";
import { formatDate } from "../utils/dateFormatter";

export function Tr({
  tr,
  handleCheckbox,
  trId,
  editRow,
  setEditRow,
  Filters,
  handleOnSave,
  handleOnDelete,
}) {
  const uploadRef = useRef(null);
  const imageRef = useRef(null);
  const thumbnail = useRef(null);
  const [data, setData] = useState(tr);

  const handleEditButton = (id) => {
    setEditRow(id);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    // const base64 = await toBase64(file);
    editRow.image = file;
    setEditRow({ ...editRow });
    if (!imageRef.current) return;
    imageRef.current.src = await toBase64(file);
  };

  const handleImagePreviewClick = () => {
    if (!uploadRef.current) return;
    uploadRef.current.click();
  };

  const handleSave = () => {
    handleOnSave();
  };
  const handleDelete = () => {
    handleOnDelete();
  };
  const handleCancel = () => {
    setEditRow(null);
    setData({ ...tr });
  };

  if (editRow?.id !== trId) {
    return (
      <tr className={styles.tr}>
        <td className={styles.td}>
          <Checkbox
            checked={data.checked}
            borderColor={data.checked ? "#3557CC" : "#B4B4B4"}
            className={styles.checkBox}
            icon={<div className={styles.checkDiv} />}
            onChange={() => handleCheckbox(data.id)}
          />
        </td>
        <td className={styles.td}>
          <img
            src={data.image}
            alt=""
            className={styles.tableImage}
            ref={thumbnail}
          />
        </td>
        <td className={styles.td}>
          {formatDate(data.date)}
        </td>
        <td className={styles.td}>
          <Button
            action={() => handleEditButton(data)}
            text={data.button.text}
          />
        </td>
      </tr>
    );
  } else {
    return (
      <tr className={styles.tr}>
        <td className={styles.td} colSpan="100%">
          <div className={styles.cardDropDowns}>{Filters}</div>
          <div className={styles.textAreaContainer}>
            <div className={styles.imageContainer}>
              <input
                type="file"
                onChange={handleFileChange}
                ref={uploadRef}
                accept="image/png, image/jpeg"
                hidden
              />
              <img
                src={data.image}
                alt=""
                className={styles.imagePreview}
                onClick={handleImagePreviewClick}
                ref={imageRef}
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <SmallButton type="primary" text="Update" onClick={handleSave} />
            <SmallButton
              type="secondary"
              text="Delete"
              onClick={handleDelete}
            />
            <SmallButton type="default" text="Cancel" onClick={handleCancel} />
          </div>
        </td>
      </tr>
    );
  }
}

export default function ImageTable({
  tableData,
  setTableData,
  editRow,
  setEditRow,
  Filters,
  handleEditText,
  handleOnSave,
  handleOnDelete,
}) {
  const handleCheckbox = (id) => {
    const trObject = tableData.td.find((tr) => tr.id === id);
    if (!trObject) return;

    const index = tableData.td.indexOf(trObject);
    tableData.td[index].checked = !tableData.td[index].checked;
    setTableData({ ...tableData });
  };

  useEffect(() => {
    console.log("tableData changed", tableData);
  }, [tableData]);

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead className={styles.tr}>
          <tr>
            {tableData.th.map((th, index) => (
              <th
                key={index}
                className={
                  index === 1 ? `${styles.longTh} ${styles.th}` : styles.th
                }
              >
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.td.map((tr, index) => {
            return (
              <Tr
                tr={tr}
                handleCheckbox={handleCheckbox}
                key={index}
                trId={tr.id}
                tanleData={tableData}
                setTableData={setTableData}
                editRow={editRow}
                setEditRow={setEditRow}
                Filters={Filters}
                handleOnSave={handleOnSave}
                handleOnDelete={handleOnDelete}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
