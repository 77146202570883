import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import styles from "../styles/cards/addCard.module.scss";
import miscsStyles from "../styles/cards/miscs.module.scss";

import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import QuantityInput from "../components/NumberInput";
import Button from "../components/Button";

import settingsApi from "../api/settings";

export default function AddQuotes() {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [defaultDays, setDefaultDays] = useState(30); // [TODO
  const [days, setDays] = useState(30);

  const handleSwitch = () => {
    setChecked(!checked);
  };

  const handleChange = (e, value) => {
    setDays(value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await settingsApi.updateSettings({
        trial: checked,
        trialDays: days,
      });
      setLoading(false);
      setNotification({
        type: "success",
        text: "Settings saved successfully",
      });
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    } catch (err) {
      setLoading(false);
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setTimeout(() => {
        setNotification(null);
      }, 3000);
    }
  };

  const getSettings = async () => {
    setLoading(true);
    const result = await settingsApi.getSettings();
    setChecked(result?.trial);
    setDefaultDays(result?.trialDays);
    setLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className={styles.cardContainer}>
      <Notification data={notification} />
      <LoadingAnim loading={loading} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Settings</span>
      </div>
      <div className={miscsStyles.miscsContainer}>
        <div className={miscsStyles.row}>
          <div className={miscsStyles.buttonRow}>
            <span className={styles.buttonText}>Trial</span>
            <Switch
              checked={checked}
              onChange={handleSwitch}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div className={miscsStyles.buttonRow}>
            <span className={styles.buttonText}>Days</span>
            <QuantityInput
              min={1}
              max={30}
              defaultValue={defaultDays}
              disabled={!checked}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button text="Update" action={handleSave} />
      </div>
    </div>
  );
}
