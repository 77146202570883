import React, { useMemo, useState } from "react";
import styles from "../styles/components/table.module.scss";
import Checkbox from "react-custom-checkbox";

import Button from "./Button";
import SmallButton from "./SmallButton";
import DatePicker from "../components/DatePicker";

import quoteIcon from "../assets/images/quote30.svg";

export function Tr({
  tr,
  handleCheckbox,
  trId,
  tableData,
  setTableData,
  editRow,
  setEditRow,
  Filters,
  handleOnSave,
  handleOnDelete,
  handleEditText,
}) {
  const handleEditButton = (id) => {
    setEditRow(id);
  };

  const handleChange = (e) => {
    handleEditText(e);
  };

  const handleSave = () => {
    handleOnSave();
  };
  const handleDelete = () => {
    handleOnDelete();
  };
  const handleCancel = () => {
    setEditRow(null);
  };

  if (editRow?.id !== trId) {
    return (
      <tr className={styles.tr}>
        <td className={styles.td}>
          <Checkbox
            checked={tr.checked}
            borderColor={tr.checked ? "#3557CC" : "#B4B4B4"}
            className={styles.checkBox}
            icon={<div className={styles.checkDiv} />}
            onChange={() => handleCheckbox(tr.id)}
          />
        </td>
        <td className={styles.td}>{tr[Object.keys(tr)[2]]}</td>
        <td className={styles.td}>{tr[Object.keys(tr)[3]]}</td>
        <td className={styles.td}>
          <Button action={() => handleEditButton(tr)} text={tr.button.text} />
        </td>
      </tr>
    );
  } else {
    return (
      <tr className={styles.tr}>
        <td className={styles.td} colSpan="100%">
          <div className={styles.cardDropDowns}>{Filters}</div>
          <div className={styles.textAreaContainer}>
            <textarea
              onChange={handleChange}
              defaultValue={tr[Object.keys(tr)[2]]}
            />
          </div>
          <div className={styles.buttonContainer}>
            <SmallButton type="primary" text="Add" onClick={handleSave} />
            <SmallButton
              type="secondary"
              text="Delete"
              onClick={handleDelete}
            />
            <SmallButton type="default" text="Cancel" onClick={handleCancel} />
          </div>
        </td>
      </tr>
    );
  }
}

export default function Table({
  tableData,
  setTableData,
  editRow,
  setEditRow,
  Filters,
  handleEditText,
  handleOnSave,
  handleOnDelete,
}) {
  const handleCheckbox = (id) => {
    const trObject = tableData.td.find((tr) => tr.id === id);
    if (!trObject) return;

    const index = tableData.td.indexOf(trObject);
    tableData.td[index].checked = !tableData.td[index].checked;
    setTableData({ ...tableData });
  };

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead className={styles.tr}>
          <tr>
            {tableData.th.map((th, index) => (
              <th
                key={index}
                className={
                  index === 1 ? `${styles.longTh} ${styles.th}` : styles.th
                }
              >
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.td.map((tr, index) => (
            <Tr
              tr={tr}
              handleCheckbox={handleCheckbox}
              key={index}
              trId={tr.id}
              tanleData={tableData}
              setTableData={setTableData}
              editRow={editRow}
              setEditRow={setEditRow}
              Filters={Filters}
              handleEditText={handleEditText}
              handleOnSave={handleOnSave}
              handleOnDelete={handleOnDelete}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
