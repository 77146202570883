import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/cards/addCard.module.scss";
import SmallButton from "../components/SmallButton";
import LoadingAnim from "../components/LoadingAnim";
import Papa from "papaparse";

export default function BulkUpload({ validation, handleUpload, loading }) {
  const [data, setData] = useState([]);
  const fileRef = useRef();
  const handleSave = () => {
    fileRef.current.click();
  };

  const handleFile = (e) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          setData([...results.data]);
        },
      });
    }
  };

  useEffect(() => {
    if (!data || !validation) return;
    if (Array.isArray(data) && data.length === 0) return;

    for (let i = 0; i < validation.length; i++) {
      if (!data[0]?.hasOwnProperty(validation[i])) {
        alert(`The file is missing the ${validation[i]} column`);
        return;
      }
    }

    if (window.confirm("Are you sure you want to upload this file?")) {
      handleUpload(data);
    }
    fileRef.current.value = null;
  }, [data]);

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Bulk Upload</span>
      </div>
      <div className={styles.buttonContainer}>
        <input
          type="file"
          accept=".csv,.xlsx,.xls"
          onChange={handleFile}
          ref={fileRef}
          hidden
        />
        <SmallButton type="primary" text="Upload" onClick={handleSave} />
      </div>
    </div>
  );
}
