const initialState = {
    emails: [],
    sentEmails: [],
};

const dropOffReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_EMAILS":
            return {
                ...state,
                emails: [...action.payload],
            };
        case "UPDATE_SENT_EMAILS":
            return {
                ...state,
                sentEmails: [...action.payload],
            };
        default:
            return state;
    }
};

export default dropOffReducer;
