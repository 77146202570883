import React, { useCallback, useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import styles from "../styles/cards/addCard.module.scss";
import miscsStyles from "../styles/cards/dropOffCard.module.scss";
import { useDispatch, useSelector } from "react-redux";

import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import QuantityInput from "../components/NumberInput";
import Button from "../components/Button";
import EmailChipsInput from "../components/EmailChipsInput";

import settingsApi from "../api/settings";
import TextInput from "../components/TextInput";
import dropOffEmailsApi from "../api/dropOffEmails";

export default function DropOffEmail() {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [defaultDays, setDefaultDays] = useState(30); // [TODO
  const [coupon, setCoupon] = useState("");
  const emails = useSelector((state) => state.dropOff)?.emails;

  const handleSend = useCallback(async () => {
    console.log(coupon);
    for (let email of emails) {
      try {
        const sent = await dropOffEmailsApi.sendEmail({ email, coupon });
        if (sent) {
          setNotification({
            type: "success",
            text: "Emails sent successfully",
          });
        }
      } catch (err) {
        setNotification({
          type: "error",
          text: "Failed to send email",
        });
        console.log(err);
      }
    }
  }, [coupon, emails]);

  const handleChange = (e) => {
    setCoupon(e.target.value);
  };

  return (
    <div className={styles.cardContainer}>
      <Notification data={notification} />
      <LoadingAnim loading={loading} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Settings</span>
      </div>
      <div className={miscsStyles.miscsContainer}>
        <div className={miscsStyles.row}>
          <div className={miscsStyles.buttonRow}>
            <span className={styles.buttonText}>Emails</span>
            <EmailChipsInput />
          </div>
        </div>
        <div className={miscsStyles.row}>
          <div className={miscsStyles.couponRow}>
            <span className={styles.buttonText}>Coupon</span>
            <TextInput onChange={handleChange} customStyles={{ height: 50 }} />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button text="Send" action={handleSend} />
      </div>
    </div>
  );
}
