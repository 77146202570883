import React, { useState } from "react";
import styles from "../styles/pages/dashboard.module.scss";

import Sidebar from "../components/Sidebar";
import SupportChatsScreen from "../screens/SupportChats.screen";

export default function DailyQuestions() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className={styles.wrapper}>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <SupportChatsScreen handleSidebarOpen={handleSidebarOpen} />
    </div>
  );
}
