import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";
import Notification from "../components/Notification";
import AddBackgroundImage from "../cards/AddBackgroundImage";
import FilterImages from "../cards/FilterImages";

export default function BackgroundImagesScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [notification, setNotification] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <Notification data={notification} />
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          {/* <AddBibleQuote
            setUpdateTrigger={setUpdateTrigger}
            updateTrigger={updateTrigger}
          /> */}
          <AddBackgroundImage
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <FilterImages
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
