import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";


import TopNav from "../components/TopNav";
import Settings from "../cards/Settings";
import Notification from "../components/Notification";

export default function MiscsScreen({ handleSidebarOpen }) {
  const [notification, setNotification] = useState(false);


  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <Notification data={notification} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <Settings />
        </div>
      </div>
    </div>
  );
}
