import React, { useState } from "react";
import styles from "../styles/pages/dashboard.module.scss";

import Sidebar from "../components/Sidebar";
import Settings from "../screens/Settings.screen";

export default function Miscs() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div className={styles.wrapper}>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Settings handleSidebarOpen={handleSidebarOpen} />
    </div>
  );
}
