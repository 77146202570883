import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "../styles/cards/chatCard.module.scss";
import { useDispatch, useSelector } from "react-redux";

import chatsApi from "../api/supportChats";
import { updateChats } from "../actions/chat";
import { io } from "socket.io-client";

import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import ding from "../assets/audio/ding.mp3";
import config from "../config/config";

const filters = {
  ALL: "ALL",
  READ: "READ",
  UNREAD: "UNREAD",
};

const ChatBalloon = ({ self, text, time, sender }) => {
  return (
    <div
      className={`${styles.balloonWrapper} ${
        !self && styles.receivedMsgBalloonWrapper
      }`}
    >
      <div className={styles.chatBalloon}>
        {text}
        <span className={styles.senderName}>{self ? "You" : sender}</span>
      </div>
    </div>
  );
};

export default function AdddUser({ updateTrigger, setUpdateTrigger }) {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [selectedChat, setSelectedChat] = useState({});
  const [selectedChatIndex, setSelectedChatIndex] = useState(0);
  const [connected, setConnected] = useState(false);
  const [message, setMessage] = useState("");
  const [newMsgEmail, setNewMsgEmail] = useState(null); //for notification
  const [filter, setFilter] = useState(filters.ALL);
  const chatScrollerRef = useRef(null);
  const chatInputRef = useRef(null);
  const socket = useRef(null);
  const dingRef = useRef(null);
  const dispatch = useDispatch();

  const socketIo = io(config.chatServerUrl, {
    transports: ["websocket", "polling"],
  });

  const conversations = useSelector((state) => state.chat)?.chats;

  const getLocalUser = () => {
    const localUserRaw = localStorage.getItem("user");
    if (!localUserRaw) return null;

    return JSON.parse(localUserRaw);
  };

  // const onSocketConnected = useCallback(() => {
  //   try {
  //     //if socket state is CONNECTING, return
  //     if (socket.current?.readyState === WebSocket.CONNECTING) return;
  //     console.log("onSocketConnected");
  //     const initialData = { action: "setAdminId", email: getLocalUser().email };
  //     socket.current.send(JSON.stringify(initialData));
  //     setConnected(true);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  // const onSocketClosed = useCallback(() => {
  //   console.log("onSocketClosed");
  //   // onConnect();
  // }, [conversations, selectedChat]);

  // const onSocketMessage = useCallback(
  //   (e) => {
  //     const data = e.data;
  //     if (!data) return;
  //     const parsedData = JSON.parse(data);

  //     const conv = conversations.find((con) => con.email === parsedData.email);

  //     if (!conv) {
  //       //if conversation not found, add new conversation
  //       const newConversation = {
  //         email: parsedData.email,
  //         name: parsedData.name,
  //         unread: true,
  //         supportChat: [
  //           {
  //             isAdmin: parsedData?.isAdmin,
  //             text: parsedData?.message?.text,
  //             senderName: parsedData.name,
  //             timeStamp: new Date().toISOString(),
  //           },
  //         ],
  //       };
  //       if (!newConversation.email) return;
  //       // dispatch(updateChats([newConversation, ...conversations]));
  //       fetchChats();
  //       if (dingRef.current) dingRef.current.play();
  //       setNewMsgEmail(newConversation.email);
  //       return;
  //     }
  //     const newMessage = {
  //       isAdmin: parsedData?.isAdmin,
  //       text: parsedData?.message?.text,
  //       senderName: parsedData.name,
  //       timeStamp: new Date().toISOString(),
  //     };
  //     // sort conversations bye updatedAt

  //     const updatedChat = [...conv.supportChat, newMessage];

  //     const index = conversations.findIndex((con) => con._id === conv._id);
  //     conversations[index].supportChat = updatedChat;
  //     conversations[index].unread = true;

  //     // setSelectedChat({ ...updatedSupportChat });
  //     const sortedConversations = conversations.sort((a, b) => {
  //       return new Date(b.updatedAt) - new Date(a.updatedAt);
  //     });
  //     dispatch(updateChats([...sortedConversations]));
  //   },
  //   [conversations, dispatch, selectedChat, selectedChatIndex]
  // );

  // const onSocketDisconnected = useCallback((e) => {
  //   socket.current?.close();
  // }, []);

  // const onConnect = useCallback(() => {
  //   if (socket.current?.readyState !== WebSocket.OPEN) {
  //     onSocketDisconnected();
  //     socket.current = new WebSocket(
  //       "wss://x223asaw48.execute-api.us-east-1.amazonaws.com/production"
  //     );

  //     socket.current.addEventListener("open", onSocketConnected);
  //     socket.current.addEventListener("close", onSocketClosed);
  //     socket.current.addEventListener("message", onSocketMessage);
  //     console.log("SOCKET: ", socket);
  //   }
  // }, [
  //   onSocketClosed,
  //   onSocketConnected,
  //   onSocketDisconnected,
  //   onSocketMessage,
  // ]);

  const onSocketMessage = useCallback((message) => {
    const data = message.data;
    if (!data) return;
    const type = message.type;

    if (type === "MESSAGE_FROM_USER") {
      console.log("MESSAGE_FROM_USER");
      const data = message.data;
      if (!data) return;

      console.log("DATA: ", data);

      const conv = conversations.find((con) => con.email === data.userEmail);

      if (!conv) {
        //if conversation not found, add new conversation
        const newConversation = {
          email: data.userEmail,
          name: data.name,
          unread: true,
          supportChat: [
            {
              isAdmin: data?.isAdmin,
              text: data?.message?.text,
              senderName: data.name,
              timeStamp: new Date().toISOString(),
            },
          ],
        };
        if (!newConversation.email) return;
        // dispatch(updateChats([newConversation, ...conversations]));
        fetchChats();
        if (dingRef.current) dingRef.current.play();
        setNewMsgEmail(newConversation.email);
        return;
      }
      const newMessage = {
        isAdmin: data?.isAdmin,
        text: data?.message?.text,
        senderName: data.name,
        timeStamp: new Date().toISOString(),
      };

      // sort conversations bye updatedAt

      const updatedChat = [...conv.supportChat, newMessage];

      const index = conversations.findIndex((con) => con._id === conv._id);
      conversations[index].supportChat = updatedChat;
      conversations[index].unread = true;

      // setSelectedChat({ ...updatedSupportChat });
      const sortedConversations = conversations.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      dispatch(updateChats([...sortedConversations]));
    }
  }, []);

  const onConnect = useCallback(() => {
    socketIo.on("connect", () => {
      setConnected(true);
      const rawUser = localStorage.getItem("user");
      if (!rawUser) return;

      const parsedUser = JSON.parse(rawUser);
      const localToken = localStorage.getItem("token");
      const sessionToken = sessionStorage.getItem("token");

      if (!localToken && !sessionToken) return;

      const initialData = {
        type: "SET_ADMIN_EMAIL",
        data: {
          email: parsedUser.email,
          token: localToken || sessionToken,
        },
      };

      socketIo.emit("message", initialData);
      console.log("SOCKET CONNECTED");

      socketIo.on("message", onSocketMessage);
    });
  }, [socketIo]);

  useEffect(() => {
    setSelectedChat(conversations[selectedChatIndex]);
  }, [conversations]);

  useEffect(() => {
    onConnect();
  }, []);

  const sendConversationRead = useCallback(
    (id) => {
      const messageData = {
        action: "setConvoRead",
        _id: id,
      };

      if (socket.current) {
        console.log("MESSAGE SENT");
        socket.current?.send(JSON.stringify(messageData));
      }
    },
    [socket]
  );

  const handleChatSelection = (index) => {
    setNewMsgEmail(null);
    setSelectedChat(conversations[index]);
    setSelectedChatIndex(index);

    const updatedChats = [...conversations];
    updatedChats[index].unread = false;
    dispatch(updateChats(updatedChats));
    sendConversationRead(conversations[index]._id);
  };

  const handleChatBlur = (index) => {
    const updatedChats = [...conversations];
    updatedChats[index].unread = false;
    dispatch(updateChats(updatedChats));
    sendConversationRead(conversations[index]._id);
  };

  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleMessageInput = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = useCallback(() => {
    if (!message.trim()) return;

    if (chatScrollerRef.current) {
      chatScrollerRef.current.scrollTop = chatScrollerRef.current.scrollHeight;
    }

    if (chatInputRef.current) chatInputRef.current.value = "";
    const updatedChat = [
      ...selectedChat.supportChat,
      {
        isAdmin: true,
        senderName: "Admin",
        text: message,
        timeStamp: new Date().toISOString(),
      },
    ];

    setSelectedChat({ ...selectedChat, supportChat: updatedChat });
    //add updated chat to conversations
    conversations[selectedChatIndex].supportChat = updatedChat;
    dispatch(updateChats([...conversations]));
    // console.log([...conversations, { ...conversations[selectedChatIndex] }]);

    const newMessageData = {
      type: "SEND_TO_USER",
      isAdmin: true,
      name: "Beatific Admin",
      data: {
        message: {
          text: message,
        },
        userEmail: selectedChat.email,
        senderName: "Beatific Admin",
      },
    };

    if (socket) {
      console.log("MESSAGE SENT");
      socketIo.emit("message", newMessageData);
    }
  }, [conversations, selectedChat, chatInputRef, message]);

  const formatMessage = (msg) => {
    //if message is too long, trim it and add ellipsis

    const trimmedMsg = msg?.slice(0, 20);
    return `${trimmedMsg}${msg?.length > 20 ? "..." : ""}`;
  };

  const fetchChats = async () => {
    const chats = await chatsApi.getAllChats();
    if (chats) dispatch(updateChats(chats));
  };

  const getButtonData = (supportChat) => {
    const lastIndex = supportChat?.length - 1;
    const lastMessage = supportChat[lastIndex];

    return {
      name: lastMessage?.senderName,
      text: formatMessage(lastMessage?.text),
    };
  };

  const handleFilterButton = (e) => {
    const filter = e.target.getAttribute("datatype");
    setFilter(filter);

    const filteredChats = conversations.filter((convo) => {
      if (filter === filters.ALL) return true;
      if (filter === filters.READ && convo?.unread) return false;
      if (filter === filters.UNREAD && !convo?.unread) return false;
      return true;
    });
    if (filteredChats.length === 0) {
      setNotification({ type: "error", text: "No chats to show" });
      return setSelectedChat({});
    }
    setSelectedChat(filteredChats[0]);
    setNotification(null);
  };

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    if (chatScrollerRef.current) {
      chatScrollerRef.current.scrollTop = chatScrollerRef.current.scrollHeight;
    }
  }, [conversations, selectedChatIndex]);

  return (
    <div className={styles.cardContainer}>
      <audio src={ding} ref={dingRef} />
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <div className={styles.filterButtonsContainer}>
          <button
            className={`${styles.filterButton} ${
              filter === filters.ALL && styles.activeButton
            }`}
            datatype={filters.ALL}
            onClick={handleFilterButton}
          >
            All
          </button>
          <button
            className={`${styles.filterButton} ${
              filter === filters.READ && styles.activeButton
            }`}
            datatype={filters.READ}
            onClick={handleFilterButton}
          >
            Read
          </button>
          <button
            className={`${styles.filterButton} ${
              filter === filters.UNREAD && styles.activeButton
            }`}
            datatype={filters.UNREAD}
            onClick={handleFilterButton}
          >
            Unread
          </button>
        </div>
        {!connected && <span className={styles.connecting}>Connecting</span>}
        {connected && <span className={styles.connected}>Connected</span>}
      </div>
      <div className={styles.chatsWrapper}>
        <div className={styles.conversationSection}>
          <div className={styles.convoWrapper}>
            {conversations.map((convo, index) => {
              const { name, text } = getButtonData(convo.supportChat);
              if (filter === filters.READ && convo?.unread) return <></>;
              if (filter === filters.UNREAD && !convo?.unread) return <></>;

              if (convo?.email)
                return (
                  <button
                    key={index}
                    className={styles.conversation}
                    onClick={() => handleChatSelection(index)}
                    onBlur={() => handleChatBlur(index)}
                    style={
                      selectedChatIndex === index
                        ? {
                            backgroundColor: "#3558CC",
                            color: "#fff",
                          }
                        : newMsgEmail === convo.email
                        ? { backgroundColor: "#1BB1F9", color: "#000" }
                        : convo?.unread
                        ? {
                            color: "#3558CC",
                          }
                        : {}
                    }
                  >
                    <span
                      className={styles.convoName}
                      style={convo?.unread ? { fontFamily: "Nunito Bold" } : {}}
                    >
                      {convo.name}
                    </span>
                    <span
                      className={styles.convoEmail}
                      style={convo?.unread ? { fontFamily: "Nunito Bold" } : {}}
                    >
                      {convo.email}
                    </span>
                    <span
                      className={styles.convoMsg}
                      style={convo?.unread ? { fontFamily: "Nunito Bold" } : {}}
                    >{`${name}: ${formatMessage(text)}`}</span>
                  </button>
                );
              return <div key={index}></div>;
            })}
          </div>
        </div>
        <div className={styles.messagesSection}>
          <div className={styles.messageScroller} ref={chatScrollerRef}>
            <div className={styles.chatBalloonContainer}>
              {selectedChat?.supportChat?.map((msg, index) => {
                if (msg.text)
                  return (
                    <ChatBalloon
                      key={index}
                      self={msg.isAdmin}
                      text={msg.text}
                      sender={msg.senderName}
                    />
                  );
                return <></>;
              })}
            </div>
            <div id="scrollToThisElement"></div>
          </div>
          <div className={styles.messageInputSecion}>
            <textarea
              onChange={handleMessageInput}
              className={styles.messageInput}
              onKeyDown={handleKeyDown}
              placeholder="Type your message here..."
              disabled={!connected}
              ref={chatInputRef}
            />
            <button
              className={styles.sendButton}
              disabled={!message.trim() || !connected}
              onClick={handleSendMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
