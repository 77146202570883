import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const admins = {
    getAdmins: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.get(
        `${config.serverUrl}admin`,
        data
      );
      resolve(response.data);
    });
  },
  addAdmin: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}admin`, data);
      resolve(response.data);
    });
  },
  updateAdmin: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.put(`${config.serverUrl}admin`, data);
      resolve(response.data);
    });
  },
  deleteAdmin: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.delete(`${config.serverUrl}admin`, {
        data: { data },
      });
      resolve(response.data);
    });
  },
  deleteBulk: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.delete(`${config.serverUrl}admin/bulk`, {
        data: {data: data},
      });
      console.log({ data: data });
      resolve(response.data);
    });
  },
};

export default admins;
