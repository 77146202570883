import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import bibleVerse from "../api/bibleVerse";

import ShortInput from "../components/ShortInput";
import DatePicker from "../components/DatePicker";
import SmallButton from "../components/SmallButton";
import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";

import quoteIcon from "../assets/images/quote30.svg";

export default function AddBibleQuote({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(null);
  const [text, setText] = useState(null);
  const [active, setActive] = useState(false);
  const [bibleBook, setBibleBook] = useState(null);
  const [bibleChapter, setBibleChapter] = useState(null);
  const [verse, setBibleVerse] = useState(null);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    if (!date || !text) {
      alert("Date and the Bible Quote cannot be empty");
      return setLoading(false);
    }
    let dateStr = new Date(date).toISOString();
    let data = {
      date: dateStr,
      text: text,
      bibleBook: bibleBook,
      verse: verse,
      chapter: bibleChapter,
    };
    const res = await bibleVerse.addBibleVerse(data);
    if (!res) return setLoading(false);
    if (res._id) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Bible Verse added successfully", type: "success" },
      });
      setLoading(false);
    } else {
      setNotification({ ...{ text: "Something went wrong", type: "error" } });
      setLoading(false);
    }
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleBibleBook = (e) => {
    setBibleBook(e.target.value);
  };

  const handleBibleChapter = (e) => {
    setBibleChapter(parseInt(e.target.value));
  };

  const handleBibleVerse = (e) => {
    setBibleVerse(parseInt(e.target.value));
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Bible Verse</span>
      </div>
      <div className={styles.cardDropDowns}>
        <ShortInput action={handleBibleBook} placeholder="Bible Book" />
        <ShortInput
          action={handleBibleChapter}
          placeholder="Chapter"
          type="number"
        />
        <ShortInput
          action={handleBibleVerse}
          placeholder="Verse"
          type="number"
        />
        <DatePicker action={(e) => setDate(e.target.value)} />
      </div>
      <div className={styles.textAreaContainer}>
        {!text && !active && (
          <div className={styles.placeholder}>
            <img src={quoteIcon} alt="" />
            Text:
          </div>
        )}
        <textarea
          onFocus={handleActive}
          onBlur={handleActive}
          onChange={handleChange}
        />
      </div>
      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
      </div>
    </div>
  );
}
