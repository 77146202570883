import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";

import DatePicker from "../components/DatePicker";
import SmallButton from "../components/SmallButton";
import Notification from "../components/Notification";
import dailyQuestions from "../api/dailyQuestions";
import LoadingAnim from "../components/LoadingAnim";

import quoteIcon from "../assets/images/quote30.svg";

export default function AddDailyQuestions({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(null);
  const [text, setText] = useState(null);
  const [active, setActive] = useState(false);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  const getId = () => {
    let user = localStorage.getItem("user");
    if (!user) user = sessionStorage.getItem("user");
    if (!user) return null;

    const jsonifiedUser = JSON.parse(user);
    return jsonifiedUser._id;
  };

  const handleSave = async () => {
    setLoading(true);
    if (!date || !text) {
      alert("Date and the question cannot be empty");
      return setLoading(false);
    }
    let id = getId();
    let dateStr = new Date(date).toISOString();
    let data = {
      date: dateStr,
      text: text,
      author: id,
    };
    const res = await dailyQuestions.addQuestion(data);
    if (!res) return;
    if (res._id) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Question added successfully", type: "success" },
      });
      setLoading(false);
    } else {
      setNotification({ ...{ text: "Something went wrong", type: "error" } });
      setLoading(false);
    }
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Daily Question</span>
      </div>
      <div className={styles.cardDropDowns}>
        <DatePicker action={(e) => setDate(e.target.value)} />
      </div>
      <div className={styles.textAreaContainer}>
        {!text && !active && (
          <div className={styles.placeholder}>
            <img src={quoteIcon} alt="" />
            Text:
          </div>
        )}
        <textarea
          onFocus={handleActive}
          onBlur={handleActive}
          onChange={handleChange}
        />
      </div>
      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
      </div>
    </div>
  );
}
