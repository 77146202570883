import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import CardOptions from "../components/CardOptions";
import Table from "../components/Table";
import DatePicker from "../components/DatePicker";
import Pagination from "../components/Pagination";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import Sort from "../components/Sort";

import userIcon from "../assets/images/userIcon.svg";

import quotes from "../api/quotes";

export default function Quotes() {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [countTotal, setCountTotal] = useState(0);
  const [notification, setNotification] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
    date: null,
  });
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleSort = (data) => {
    tableOptions.sort = data;
    setTableOptions({ ...tableOptions });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const handleEditDateChange = (e) => {
    if (!editRow) return;
    editRow.date = e.target.value;
  };
  const handleAuthorChange = (e) => {
    if (!editRow) return;
    editRow.author = e.target.value;
  };

  const handleEditText = (e) => {
    if (!editRow) return;
    editRow.quote = e.target.value;
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let data = {
      date: new Date(editRow.date).toISOString(),
      text: editRow.quote,
      id: editRow.id,
      author: editRow.author,
    };
    let res = await quotes.updateQuote(data);
    if (res._id) {
      setNotification({
        type: "success",
        text: "Question updated successfully",
      });
      setEditRow(null);
      setUpdateTrigger(!updateTrigger);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setUpdateTrigger(!updateTrigger);
    setLoading(false);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this quote?") === true
    ) {
      setLoading(true);
      let res = await quotes.deleteQuote({ id: editRow.id });
      if (res.message.includes("Deleted")) {
        setNotification({
          type: "success",
          text: "Quote deleted successfully",
        });
        setEditRow(null);
        setUpdateTrigger(!updateTrigger);
        setLoading(false);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
    }
  };

  const handleBulkDelete = async () => {
    setLoading(true);
    if (!window.confirm("Are you sure you want to delete these quotes?"))
      return;
    let checked = tableData?.td.filter((td) => td.checked);

    let res = await quotes.deleteBulk(checked);
    if (res.message.includes("Deleted")) {
      setNotification({
        type: "success",
        text: "Question deleted successfully",
      });
      setEditRow(null);
      setUpdateTrigger(!updateTrigger);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];
  const [tableData, setTableData] = useState({
    th: ["No.", "Quotes", "Date", "Edit"],
    td: [],
  });

  useEffect(() => {
    setLoading(true);
    const getTableData = async () => {
      const data = await quotes.getQuotes(tableOptions);
      setCountTotal(data.countTotal);

      tableData.td = [];
      data.quote.map((quote, index) => {
        tableData.td.push({
          id: quote._id,
          checked: false,
          quote: quote.text,
          date: new Date(quote.updatedAt).toLocaleDateString(),
          button: { action: buttonAction, text: "Edit" },
          author: quote.author,
        });
      });
      setTableData({ ...tableData });
      setLoading(false);
    };
    getTableData();
  }, [tableOptions, date, updateTrigger]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Quotes</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <Sort
            text="Author"
            action={() =>
              handleSort({
                author: !tableOptions?.sort?.author
                  ? 1
                  : tableOptions?.sort?.author === -1
                  ? 1
                  : -1,
              })
            }
            positive={tableOptions?.sort?.author === -1 ? false : true}
          />
          <DatePicker action={handleDateChange} />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleDateChange={handleEditDateChange}
                editRow={editRow}
                handleAuthorChange={handleAuthorChange}
              />
            }
            handleEditText={handleEditText}
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ handleDateChange, editRow, handleAuthorChange }) {
  return (
    <>
      <DatePicker action={handleDateChange} defaultValue={editRow?.date} />
      <ShortInput
        icon={userIcon}
        action={handleAuthorChange}
        placeholder="Author"
        defaultValue={editRow?.author}
      />
    </>
  );
}
