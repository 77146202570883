import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";
import AddQuotes from "../cards/AddQuotes";
import BulkUpload from "../cards/BulkUpload";
import FilterQuotes from "../cards/FilterQuotes";
import Notification from "../components/Notification";

import quotes from "../api/quotes";

export default function QuotesScreen({ handleSidebarOpen }) {
  const validation = ["date", "quote", "author"];
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpload = async (data) => {
    setLoading(true);
    let formattedArray = data.map((item) => {
      item.text = item.quote;
      return { ...item, date: item.date };
    });

    let res = await quotes.bulkUpload(formattedArray);
    if (Array.isArray(res) && res.length > 0) {
      setNotification({
        type: "success",
        text: "Quotes uploaded successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setLoading(false);
    } else {
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setLoading(false);
    }
  };
  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <Notification data={notification} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <AddQuotes
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <BulkUpload
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            validation={validation}
            handleUpload={handleUpload}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <FilterQuotes
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
