import React, { useState } from "react";
import api from "../api/api";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../styles/pages/loginPage.css";

export default function LoginScreen({ setLoggedIn }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const response = await api.login({
      email: email,
      password: password,
    });
    setLoading(false);
    if (response._id) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          email: response.email,
          _id: response._id,
          type: response.type,
          name: response.name,
        })
      );

      if (remember) {
        localStorage.setItem("token", response.token);
      } else {
        sessionStorage.setItem("token", response.token);
      }
      setLoggedIn(true);
    } else {
      setError(response.message);
      console.log(response.message);
    }
  };

  return (
    <div>
      <h3>Beatific Sign In</h3>

      <div className="mb-3">
        <label>Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
            onChange={(e) => setRemember(e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            &nbsp; Remember me
          </label>
        </div>
        <span style={{ color: "#ED3A50" }}>{error}</span>
      </div>

      <div className="d-grid">
        <button
          onClick={handleSubmit}
          className="btn btn-primary"
          disabled={loading}
        >
          Submit
        </button>
      </div>
      <p className="forgot-password text-right">
        Forgot <a href="#">password?</a>
      </p>
    </div>
  );
}
