const initialState = {
  chats: [],
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CHAT":
      console.log("PAYLOAD: ", action.payload);
      return {
        ...state,
        chats: [...action.payload],
      };

    default:
      return state;
  }
};

export default chatReducer;
