import React from "react";
import styles from "../styles/components/button.module.scss";

export default function Button({ text, action, disabled }) {
  return (
    <div className={styles.buttonContainer}>
      <button onClick={action} disabled={disabled}>
        {text}
      </button>
    </div>
  );
}
