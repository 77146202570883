import React, { useState } from "react";
import styles from "../styles/cards/addCard.module.scss";
import admins from "../api/admins";

import SmallButton from "../components/SmallButton";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";

import emailIcon from "../assets/images/emailIcon.svg";
import passwordIcon from "../assets/images/passwordIcon.svg";
import userIcon from "../assets/images/userIcon.svg";
import quoteIcon from "../assets/images/quote30.svg";
import LoadingAnim from "../components/LoadingAnim";

export default function AddAdmin({ updateTrigger, setUpdateTrigger }) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState(null);

  const handleSave = async () => {
    if (!name || !email || !password) return;
    setLoading(true);
    let data = {
      name: name,
      email: email,
      password: password,
      type: "admin",
    };
    const res = await admins.addAdmin(data);
    if (!res) return setLoading(false);
    if (res._id) {
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "User added successfully", type: "success" },
      });
      setLoading(false);
      return;
    }
    if (!res.success) {
      setNotification({ ...{ text: res.message, type: "error" } });
      setLoading(false);
      return;
    }
    setNotification({ ...{ text: "Something went wrong", type: "error" } });
    setLoading(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Admin</span>
      </div>
      <div className={styles.cardDropDowns}>
        <ShortInput
          action={handleNameChange}
          placeholder="Name"
          icon={userIcon}
        />
        <ShortInput
          action={handleEmailChange}
          placeholder="Email"
          icon={emailIcon}
          type="email"
        />
        <ShortInput
          action={handlePasswordChange}
          placeholder="Password"
          icon={passwordIcon}
          type="password"
        />
      </div>

      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Add" onClick={handleSave} />
      </div>
    </div>
  );
}
