import React, { useEffect } from "react";
import styles from "../styles/components/dropDown.module.scss";

import nextIcon from "../assets/images/nextIcon.svg";

export default function Sort({ text, action, positive }) {
  useEffect(() => {
    console.log(positive);
  }, [positive]);

  return (
    <div className={`${styles.darkContainer} ${styles.dropDownContainer}`}>
      <button className={styles.timeSelect} onClick={action}>
        {text}
      </button>
      <img
        src={nextIcon}
        alt=""
        className={styles.arrowDown}
        style={
           positive
            ? { transform: `rotate(270deg)` }
            : { transform: `rotate(90deg)` }
        }
      />
    </div>
  );
}
