import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";

import Chats from "../cards/Chats";


export default function SupportChatsScreen({ handleSidebarOpen }) {
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <div className={styles.homeContainer}>
            <TopNav handleSidebarOpen={handleSidebarOpen} />
            <div className={styles.content}>
                <div className={styles.dailyQuestionsContainer}>
                    <Chats
                        updateTrigger={updateTrigger}
                        setUpdateTrigger={setUpdateTrigger}
                    />
                </div>
            </div>
        </div>
    );
}
