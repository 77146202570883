import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import styles from "../styles/components/dropOff.module.scss";
import { useDispatch, useSelector } from "react-redux";

function EmailChipsInput() {
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setInputValue(event?.target?.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();

      const isAnArray = inputValue.includes(",");
      if (isAnArray) {
        const emailArr = inputValue.split(",").map((item) => item.trim());
        const emailsToAdd = [];
        for (let email of emailArr) {
          if (!emails.includes(email) && email.trim() !== "")
            emailsToAdd.push(email);
        }
        if (emailsToAdd.length) {
          setEmails([...emails, ...emailsToAdd]);
        } else {
          setInputValue("");
          return;
        }
        setInputValue("");
        return;
      }

      const value = inputValue.trim();
      if (value && !emails.includes(value)) {
        setEmails([...emails, value]);
        setInputValue("");
        return;
      }
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setEmails((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleOnPaste = (e) => {
    setTimeout(() => {
      const value = inputValue;
      if (!value) return;

      const emailArr = value?.split(",").map((item) => item.trim());
      for (let email of emailArr) {
        if (!emails.includes(email) && email.trim() !== "")
          setEmails((prevRes) => [...prevRes, email]);
      }
    }, 300);
  };

  useEffect(() => {
    dispatch({ type: "UPDATE_EMAILS", payload: emails });
  }, [emails]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {emails.map((email, index) => (
          <Chip
            key={index}
            label={email}
            onDelete={handleDeleteChip(email)}
            style={{ marginRight: 5 }}
          />
        ))}
      </div>
      <TextField
        label="Emails"
        variant="outlined"
        placeholder="Enter email(s) separated by comma"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onPaste={handleOnPaste}
      />
    </div>
  );
}

export default EmailChipsInput;
