import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const api = {
  login: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      try {
        let response = await axios.post(`${config.serverUrl}admin/login`, data);
        resolve(response.data);
      } catch (err) {
        resolve(err.response.data);
      }
    });
  },
  getDashboardData: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(
        `${config.serverUrl}admin/dashboard`,
        data
      );
      resolve(response.data);
    });
  },
  getQuestions: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(
        `${config.serverUrl}questions/page`,
        data
      );
      resolve(response.data);
    });
  },
  getQuotes: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}quotes/page`, data);
      resolve(response.data);
    });
  },
  getBibleVerses: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}bible/page`, data);
      resolve(response.data);
    });
  },
  getUsers: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}users/page`, data);
      resolve(response.data);
    });
  },
};

export default api;
