import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const chats = {
  getAllChats: () => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.get(`${config.serverUrl}supportChats`);
      resolve(response.data);
    });
  },
};

export default chats;
