import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const pricingPlans = {
  getPlans: () => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.get(`${config.serverUrl}pricingPlans`);
      resolve(response.data);
    });
  },
  addPlans: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}pricingPlans`, data);
      resolve(response.data);
    });
  },
  updatePlans: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.put(`${config.serverUrl}pricingPlans`, data);
      resolve(response.data);
    });
  },
};

export default pricingPlans;
