import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";

import AddDailyQuestions from "../cards/AddDailyQuestions";
import BulkUpload from "../cards/BulkUpload";
import FilterQuestions from "../cards/FilterQuestions";
import dailyQuestions from "../api/dailyQuestions";
import Notification from "../components/Notification";

export default function DailyQuestionsScreen({ handleSidebarOpen }) {
  const validation = ["date", "question", "author"];
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUpload = async (data) => {
    setLoading(true);
    let formattedArray = data.map((item) => {
      let date = new Date(item.date);
      let formattedDate = date.toISOString();
      item.text = item.question;
      delete formattedDate["question"];
      return { ...item, date: formattedDate };
    });

    let res = await dailyQuestions.bulkUpload(formattedArray);
    if (Array.isArray(res) && res.length > 0) {
      setNotification({
        type: "success",
        text: "Questions uploaded successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setLoading(false);
    } else {
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setLoading(false);
    }
  };

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <Notification data={notification} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <AddDailyQuestions
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <BulkUpload
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            validation={validation}
            handleUpload={handleUpload}
            loading={loading}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <FilterQuestions
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
