import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../styles/components/sidebar.module.scss";
import { Link } from "react-router-dom";

import homeIcon from "../assets/images/homeIcon.svg";
import quesionsIcon from "../assets/images/questionsIcon.svg";
import quotesIcon from "../assets/images/quotesIcon.svg";
import usersIcon from "../assets/images/usersIcon.svg";
import bibleIcon from "../assets/images/bibleIcon.svg";
import nextIcon from "../assets/images/nextIcon.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import coinIcon from "../assets/images/pricing.svg";
import adminIcon from "../assets/images/adminIcon.svg";
import logoutIcon from "../assets/images/logout.svg";
import miscsIcon from "../assets/images/options.svg";
import emailIcon from "../assets/images/dropOffEmailIcon.svg";

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const [isMobile, setIsMobile] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const navItems = [
    { icon: homeIcon, title: "Home", path: "/" },
    { icon: quesionsIcon, title: "Daily Questions", path: "/daily-questions" },
    { icon: quotesIcon, title: "Quotes", path: "/daily-quotes" },
    { icon: bibleIcon, title: "Bible", path: "/bible-verse" },
    { icon: bibleIcon, title: "Back G Images", path: "/background-images" },
    { icon: usersIcon, title: "Users", path: "/users" },
    { icon: coinIcon, title: "Pricing", path: "/pricing" },
    { icon: adminIcon, title: "Admins", path: "/admins" },
    { icon: quesionsIcon, title: "Support Chats", path: "/support-chats" },
    { icon: miscsIcon, title: "Settings", path: "/settings" },
    { icon: emailIcon, title: "Drop off emails", path: "/drop-off-email" },
  ];

  const handleSignOut = () => {
    let text = "Are you sure you want to sign out?";
    // eslint-disable-next-line no-restricted-globals
    if (confirm(text)) {
      sessionStorage.removeItem("token");
      localStorage.removeItem("token");
      window.location.href = "https://admin.beatific.co";
    }
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      let userObj = JSON.parse(user);
      if (userObj.type === "super_admin") {
        setSuperAdmin(true);
      }
    }
  }, []);

  const sidebarStyles =
    isMobile && sidebarOpen
      ? `${styles.sidebarContainer} ${styles.sidebarOpen}`
      : styles.sidebarContainer;

  return (
    <div className={sidebarStyles}>
      {isMobile && (
        <button className={styles.closeBtn} onClick={handleSidebarOpen}>
          <img src={closeIcon} alt="" />
        </button>
      )}
      <div className={styles.logoContainer}>Beatific Admin</div>
      <div className={styles.navContainer}>
        {navItems.map((item, index) => {
          if (item.path === "/admins" && !superAdmin) return null;
          return (
            <Link to={item.path} key={index} onClick={handleSidebarOpen}>
              <div
                className={
                  path === item.path
                    ? `${styles.navItem} ${styles.active}`
                    : styles.navItem
                }
              >
                <div className={styles.navItemIcon}>
                  <img src={item.icon} alt="" />
                </div>
                <span className={styles.navItemText}>{item.title}</span>
                <img src={nextIcon} alt="" className={styles.nextIcon} />
              </div>
            </Link>
          );
        })}
        <button className={styles.logoutBtn} onClick={handleSignOut}>
          <img src={logoutIcon} alt="sign out" />
          <span>Sign out</span>
        </button>
      </div>
    </div>
  );
}
