import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const users = {
  getUsers: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(
        `${config.serverUrl}users/admin/page`,
        data
      );
      resolve(response.data);
    });
  },
  addUser: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}users/admin`, data);
      resolve(response.data);
    });
  },
  updateUser: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.put(`${config.serverUrl}users/admin`, data);
      resolve(response.data);
    });
  },
  deleteUser: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.delete(`${config.serverUrl}users/admin`, {
        data: { data },
      });
      resolve(response.data);
    });
  },
  deleteBulk: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.delete(`${config.serverUrl}users/admin/bulk`, {
        data: { data: data },
      });
      console.log({ data: data });
      resolve(response.data);
    });
  },
  downloadUnpaidUsers: (isUnpaid) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.get(
        `${config.serverUrl}users/admin/download-${isUnpaid ? "unpaid" : "paid"}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${isUnpaid ? "unpaid" : "paid"}-users.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve(response.data);
    });
  },
};

export default users;
