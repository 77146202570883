import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/cards/addCard.module.scss";

import images from "../api/images";

import DatePicker from "../components/DatePicker";
import SmallButton from "../components/SmallButton";
import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";
import ImageTable from "../components/ImageTable";

export default function AddBackgroundImage({
  updateTrigger,
  setUpdateTrigger,
}) {
  const [date, setDate] = useState(null);
  const [file, setFile] = useState(null);
  const [notification, setNotification] = useState(null);
  const [fileInputText, setFileInputText] = useState("Choose File");
  const [loading, setLoading] = useState(false);
  const uploadRef = useRef();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const base64 = await toBase64(file);
    // setFile(file);
    setFile(base64);
  };

  const handleSave = async () => {
    if (!date || !file) {
      alert("Date and the image cannot be empty");
      return;
    }

    setLoading(true);
    setFileInputText("Uploading...");
    let dateStr = new Date(date).toISOString();
    let data = {
      image: file,
      date: dateStr,
    };
    console.log(data);
    const res = await images.addImage(data);
    uploadRef.current.value = null;
    if (!res) return setLoading(false);
    if (res._id) {
      console.log("Image uploaded successfully");
      setFileInputText("Uploading done.");
      setUpdateTrigger(!updateTrigger);
      setNotification({
        ...{ text: "Image uploaded successfully", type: "success" },
      });
      setLoading(false);
    } else {
      setNotification({ ...{ text: "Something went wrong", type: "error" } });
      setFileInputText("Uploading failed.");
      setTimeout(() => {
        setFileInputText("Choose File");
      }, 3000);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(date);
  }, [date]);

  const handleUploadClick = () => {
    uploadRef.current.click();
  };

  useEffect(() => {
    if (!file) return;
    if (!window.confirm("Are you sure you want to upload this file?")) return;

    handleSave();
  }, [file]);

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading} />
      <Notification data={notification} />
      <div className={styles.cardHeader}>
        <span className={styles.title}>Add Background Images</span>
      </div>
      <div className={styles.cardDropDowns}>
        <DatePicker action={(e) => setDate(e.target.value)} />
        <div className={styles.buttonContainer}>
          <input
            type="file"
            onChange={handleFileChange}
            ref={uploadRef}
            accept="image/png, image/jpeg"
            hidden
          />
          <SmallButton
            type="primary"
            text="Upload"
            onClick={handleUploadClick}
          />
          <span className={styles.uploadStatus}>{fileInputText}</span>
        </div>
      </div>
    </div>
  );
}
