import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const images = {
  getImages: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}images/page`, data);
      resolve(response.data);
    });
  },
  // addImage: (data) => {
  //   // getToken();
  //   return new Promise(async (resolve) => {
  //     const configs = {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     };
  //     const formData = new FormData();
  //     formData.append("image", data.image);
  //     formData.append("date", data.date);

  //     let response = await axios.post(
  //       `${config.serverUrl}images`,
  //       formData,
  //       configs
  //     );
  //     resolve(response.data);
  //   });
  // },
  addImage: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}images`, data);
      resolve(response.data);
    });
  },
  updateImage: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      // let response = await axios.post(`${config.serverUrl}images`, data);
      // console.log(response);
      // resolve(response.data);
      const configs = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log(data);
      const formData = new FormData();
      formData.append("image", data.image);
      formData.append("date", data.date);
      formData.append("id", data.id);

      let response = await axios.post(
        `${config.serverUrl}images`,
        formData,
        configs
      );
      resolve(response.data);
    });
  },
  deleteImage: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      console.log(data);
      let response = await axios.delete(`${config.serverUrl}images`, {
        data: data,
      });
      console.log({ data: data });
      resolve(response.data);
    });
  },
  deleteBulk: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.delete(`${config.serverUrl}images/bulk`, {
        data: { data: data },
      });
      console.log({ data: data });
      resolve(response.data);
    });
  },
  //   bulkUpload: (data) => {
  //     return new Promise(async (resolve) => {
  //       let response = await axios.post(`${config.serverUrl}bible/bulk`, data);
  //       resolve(response.data);
  //     });
  //   },
};

export default images;
