import axios from "axios";
import config from "../config/config";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const getSettings = () => {
  return new Promise(async (resolve) => {
    let response = await axios.get(`${config.serverUrl}settings`);
    resolve(response.data);
  });
};

const updateSettings = (data) => {
  getToken();
  return new Promise(async (resolve) => {
    let response = await axios.post(`${config.serverUrl}settings`, data);
    resolve(response.data);
  });
};

const settings = { getSettings, updateSettings };
export default settings;
