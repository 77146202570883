import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import LoginPage from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import DailyQuestions from "./pages/DailyQuestions";
import Quotes from "./pages/Quotes";
import Bible from "./pages/Bible";
import BackgroundImages from "./pages/BackgroundImages";
import Users from "./pages/Users";
import Pricing from "./pages/Pricing";
import Admins from "./pages/Admins";
import SupportChats from "./pages/SupportChats";
import Settings from "./pages/Settings";
import DropOffEmail from "./pages/DropOffEmail";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    if (!token) token = sessionStorage.getItem("token");
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  if (!loggedIn) {
    if (window.location.pathname !== "/") window.location.href = "/";
    return (
      <LoginPage setLoggedIn={setLoggedIn} setSuperAdmin={setSuperAdmin} />
    );
  } else {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/daily-questions" element={<DailyQuestions />} />
            <Route path="/daily-quotes" element={<Quotes />} />
            <Route path="/bible-verse" element={<Bible />} />
            <Route path="/background-images" element={<BackgroundImages />} />
            <Route path="/users" element={<Users />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/admins" element={<Admins />} />
            <Route path="/support-chats" element={<SupportChats />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/drop-off-email" element={<DropOffEmail />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
