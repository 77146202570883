import React from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Login from "../screens/Login.screen";

function LoginPage({ setLoggedIn }) {
  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Routes>
              <Route
                exact
                path="/"
                element={<Login setLoggedIn={setLoggedIn} />}
              />
              <Route
                path="/sign-in"
                element={<Login setLoggedIn={setLoggedIn} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default LoginPage;
