import { combineReducers } from "redux";
import chatReducer from "./chatReducer";
import dropOffReducer from "./dropOffReducer";

const rootReducer = combineReducers({
  chat: chatReducer,
  dropOff: dropOffReducer,
});

export default rootReducer;
