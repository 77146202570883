import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";

import AddAdmin from "../cards/AddAdmin";
import FilterAdmins from "../cards/FilterAdmins";

export default function AdminsScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <AddAdmin
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <FilterAdmins
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
      </div>
    </div>
  );
}
