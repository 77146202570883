import React from "react";
import styles from "../styles/components/smallButton.module.scss";

export default function SmallButton({ type, text, onClick }) {
  const btnStyle =
    type === "primary"
      ? `${styles.primary} ${styles.smallButton}`
      : type === "secondary"
      ? `${styles.secondary} ${styles.smallButton}`
      : `${styles.smallButton}`;

  return (
    <button className={btnStyle} onClick={onClick}>
      {text}
    </button>
  );
}
