import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import ImageTable from "../components/ImageTable";
import DatePicker from "../components/DatePicker";
import images from "../api/images";
import Pagination from "../components/Pagination";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";

import userIcon from "../assets/images/userIcon.svg";

export default function FilterImages({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 3,
  });

  const [tableData, setTableData] = useState({
    th: ["No.", "Image", "Date", "Edit"],
    td: [],
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    let checked = tableData?.td.filter((td) => td.checked);
    setLoading(true);
    let res = await images.deleteBulk(checked);
    if (res.message.includes("Deleted")) {
      setNotification({
        type: "success",
        text: "Image deleted successfully",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];

  const buttonAction = (data) => {};
  const handleDateChange = (e) => {
    console.log(e.target.value);
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const handleEditDateChange = (e) => {
    if (!editRow) return;
    editRow.date = new Date(e.target.value).toISOString().split("T")[0];
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let data = {
      date: new Date(editRow.date).toISOString(),
      image: editRow.image,
      id: editRow.id,
    };
    let res = await images.updateImage(data);
    if (res._id) {
      setNotification({
        type: "success",
        text: "Image updated successfully",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this image?") === true
    ) {
      setLoading(true);
      let res = await images.deleteImage({ id: editRow.id });
      if (res.success) {
        setNotification({
          type: "success",
          text: "Image deleted successfully",
        });
        setEditRow(null);
        setLoading(false);
        setUpdateTrigger(!updateTrigger);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
    }
  };

  useEffect(() => {
    setTableData({ ...tableData, td: [] });
    const getTableData = async () => {
      setLoading(true);
      const data = await images.getImages(tableOptions);
      setCountTotal(data.countTotal);

      tableData.td = [];
      data.images.map((image, index) => {
        console.log(image.date);
        tableData.td.push({
          id: image._id,
          checked: false,
          image: image.image,
          date: new Date(image.date).toDateString(),
          button: { action: buttonAction, text: "Edit" },
        });
      });
      setLoading(false);
      setTableData({ ...tableData });
    };
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    console.log(editRow, "editRow");
  }, [editRow]);

  return (
    <>
      <div className={styles.cardContainer}>
        <LoadingAnim loading={loading} />
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Filter</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
        </div>
        <div className={styles.tableWrapper}>
          <ImageTable
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleDateChange={handleEditDateChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ handleDateChange, editRow }) {
  return (
    <>
      <DatePicker action={handleDateChange} defaultValue={editRow?.date} />
    </>
  );
}
