import React, { useState, useEffect } from "react";
import styles from "../styles/components/notification.module.scss";

export default function Notification({ data }) {
  const [show, setShow] = useState(true);

  const [containerClass, setContainerClass] = useState(
    data
      ? data.type === "success"
        ? `${styles.success} ${styles.notificationContainer} ${styles.show}`
        : `${styles.error} ${styles.notificationContainer} ${styles.show}`
      : ""
  );

  useEffect(() => {
    setShow(true);
    let timer = setTimeout(() => {
      setShow(false);
      setContainerClass(
        data
          ? data.type === "success"
            ? `${styles.success} ${styles.notificationContainer} ${styles.hide}`
            : `${styles.error} ${styles.notificationContainer} ${styles.hide}`
          : ""
      );
    }, 5000);

    return () => clearTimeout(timer);
    
  }, [data]);

  useEffect(() => {
    setContainerClass(
      data
        ? data.type === "success"
          ? `${styles.success} ${styles.notificationContainer} ${styles.show}`
          : `${styles.error} ${styles.notificationContainer} ${styles.show}`
        : ""
    );
  }, [data]);

  if (data) {
    return (
      <div className={containerClass}>
        <div className={styles.notificationText}>{data.text}</div>
      </div>
    );
  } else {
    return <></>;
  }
}
