import React, { useState } from "react";
import styles from "../styles/screens/home.module.scss";

import TopNav from "../components/TopNav";

import AddUser from "../cards/AddUser";
import BulkUpload from "../cards/BulkUpload";
import FilterUsers from "../cards/FilterUsers";

export default function UsersScreen({ handleSidebarOpen }) {
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.homeContainer}>
      <TopNav handleSidebarOpen={handleSidebarOpen} />
      <div className={styles.content}>
        <div className={styles.dailyQuestionsContainer}>
          <AddUser
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
          />
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <BulkUpload loading={loading}/>
        </div>
        <div className={styles.dailyQuestionsContainer}>
          <FilterUsers updateTrigger={updateTrigger} setUpdateTrigger={setUpdateTrigger}/>
        </div>
      </div>
    </div>
  );
}
