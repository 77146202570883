import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import CardOptions from "../components/CardOptions";
import UsersTable from "../components/UsersTable";
import Pagination from "../components/Pagination";
import ShortInput from "../components/ShortInput";
import Notification from "../components/Notification";
import LoadingAnim from "../components/LoadingAnim";

import userIcon from "../assets/images/userIcon.svg";
import emailIcon from "../assets/images/emailIcon.svg";

import users from "../api/users";

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [editRow, setEditRow] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [notification, setNotification] = useState(null);
  const [countTotal, setCountTotal] = useState(0);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    let checked = tableData?.td.filter((td) => td.checked);

    setLoading(true);

    let res = await users.deleteBulk(checked);
    if (res.message.includes("Deleted")) {
      setNotification({
        type: "success",
        text: "Users deleted successfully",
      });
      setEditRow(null);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];

  const [tableData, setTableData] = useState({
    th: ["No.", "Name", "Email", "Subscription", "Date", "Edit"],
    td: [],
  });

  const buttonAction = (data) => {
    console.log(data);
  };

  const handleNameChange = (e) => {
    if (!editRow) return;
    editRow.name = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleEmailChange = (e) => {
    if (!editRow) return;
    editRow.email = e.target.value;
    setEditRow({ ...editRow });
  };

  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  //   setTableOptions({ ...tableOptions, date: e.target.value });
  // };

  //save
  const handleOnSave = async () => {
    setLoading(true);
    console.log(editRow, "save");
    let data = {
      email: editRow.email,
      name: editRow.name,
      id: editRow.id,
    };
    let res = await users.updateUser(data);
    if (res._id) {
      setNotification({
        type: "success",
        text: "User updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (window.confirm("Are you sure you want to delete this user?") === true) {
      setLoading(true);
      let res = await users.deleteUser({ id: editRow.id });
      if (res.message.includes("Deleted")) {
        setNotification({
          type: "success",
          text: "User deleted successfully",
        });
        setEditRow(null);
        setLoading(false);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setLoading(false);
      setEditRow(null);
    }
  };

  useEffect(() => {
    console.log(editRow);
  }, [editRow]);

  useEffect(() => {
    setLoading(true);
    const getTableData = async () => {
      const data = await users.getUsers(tableOptions);
      setCountTotal(data.countTotal);

      tableData.td = [];
      data.users.map((user, index) => {
        tableData.td.push({
          id: user._id,
          checked: false,
          name: user.name,
          email: user.email,
          subscription:
            user.subscriptionAmount && user.payPeriod
              ? `${user.subscriptionAmount} ${
                  user.payPeriod.charAt(0).toUpperCase() +
                  user.payPeriod.slice(1)
                }`
              : "N/A",
          date: new Date(user.updatedAt).toLocaleDateString(),
          button: { action: buttonAction, text: "Edit" },
        });
      });
      setLoading(false);
      setTableData({ ...tableData });
    };
    getTableData();
  }, [tableOptions, updateTrigger]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Users</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <UsersTable
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleNameChange={handleNameChange}
                handleEmailChange={handleEmailChange}
                editRow={editRow}
              />
            }
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({ editRow, handleNameChange, handleEmailChange }) {
  return (
    <>
      <ShortInput
        defaultValue={editRow?.name}
        placeholder="Name"
        icon={userIcon}
        action={handleNameChange}
      />
      <ShortInput
        defaultValue={editRow?.email}
        placeholder="Email"
        icon={emailIcon}
        action={handleEmailChange}
      />
    </>
  );
}
