import React, { useState, useEffect } from "react";
import styles from "../styles/cards/dailyQuestions.module.scss";

import Table from "../components/Table";
import DatePicker from "../components/DatePicker";
import bibleVerse from "../api/bibleVerse";
import Pagination from "../components/Pagination";
import Notification from "../components/Notification";
import ShortInput from "../components/ShortInput";
import CardOptions from "../components/CardOptions";
import LoadingAnim from "../components/LoadingAnim";

import bookIcon from "../assets/images/bookIcon.svg";
import verseIcon from "../assets/images/verseIcon.svg";
import chapterIcon from "../assets/images/chapterIcon.svg";

export default function FilterBible({ updateTrigger, setUpdateTrigger }) {
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [countTotal, setCountTotal] = useState(0);
  const [notification, setNotification] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tableOptions, setTableOptions] = useState({
    page: 1,
    limit: 10,
  });

  const buttonAction = (data) => {
    console.log(data);
  };

  const [tableData, setTableData] = useState({
    th: ["No.", "Bible Verse", "Date", "Edit"],
    td: [],
  });

  const handleBulkDelete = async () => {
    if (!window.confirm("Are you sure you want to delete these users?")) return;
    let checked = tableData?.td.filter((td) => td.checked);

    setLoading(true);
    let res = await bibleVerse.deleteBulk(checked);
    if (res.message.includes("Deleted")) {
      setNotification({
        type: "success",
        text: "Question deleted successfully",
      });
      setEditRow(null);
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
  };

  const options = [
    {
      text: "Delete",
      action: handleBulkDelete,
    },
  ];

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setTableOptions({ ...tableOptions, date: e.target.value });
  };

  const handleEditDateChange = (e) => {
    if (!editRow) return;
    editRow.date = e.target.value;
  };

  const handleBookChange = (e) => {
    if (!editRow) return;
    editRow.bibleBook = e.target.value;
    setEditRow({ ...editRow });
  };
  const handleVerseChange = (e) => {
    if (!editRow) return;
    editRow.verse = parseInt(e.target.value);
    setEditRow({ ...editRow });
  };
  const handleChapterChange = (e) => {
    if (!editRow) return;
    editRow.chapter = parseInt(e.target.value);
    setEditRow({ ...editRow });
  };

  const handleEditText = (e) => {
    if (!editRow) return;
    editRow.text = e.target.value;
    console.log(e.target.value);
  };

  //save
  const handleOnSave = async () => {
    setLoading(true);

    const currDate = new Date(editRow.date);
    let day = currDate.getDate();
    let month = currDate.getMonth() + 1;
    const year = currDate.getFullYear();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;

    let formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;

    if (editRow.date?.includes("/")) {
      formattedDate = `${year}-${day}-${month}T00:00:00.000Z`;
    }

    let data = {
      date: new Date(formattedDate),
      text: editRow.text,
      bibleBook: editRow.bibleBook,
      chapter: editRow.chapter,
      verse: editRow.verse,
      id: editRow.id,
    };

    let res = await bibleVerse.updateQuote(data);
    if (res._id) {
      setNotification({
        type: "success",
        text: "Question updated successfully",
      });
      setUpdateTrigger(!updateTrigger);
      setEditRow(null);
      setLoading(false);
      return;
    }
    setNotification({
      type: "error",
      text: "Something went wrong",
    });
    setEditRow(null);
    setLoading(false);
    setUpdateTrigger(!updateTrigger);
  };
  //delete
  const handleOnDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete this question?") === true
    ) {
      setLoading(true);
      let res = await bibleVerse.deleteQuote({ id: editRow.id });
      if (res.message.includes("Deleted")) {
        setNotification({
          type: "success",
          text: "Question deleted successfully",
        });
        setEditRow(null);
        setLoading(false);
        return;
      }
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setEditRow(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("loading");
    const getTableData = async () => {
      setLoading(true);
      const data = await bibleVerse.getBibleVerse(tableOptions);
      setCountTotal(data.countTotal);

      tableData.td = [];
      data.bibleQuotes.map((quote, index) => {
        tableData.td.push({
          id: quote._id,
          checked: false,
          question: quote.text,
          date: new Date(quote.date).toLocaleDateString(),
          button: { action: buttonAction, text: "Edit" },
        });
      });
      setTableData({ ...tableData });
      setLoading(false);
    };
    getTableData();
  }, [tableOptions, updateTrigger, date]);

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  return (
    <>
      <div className={styles.cardContainer}>
        <Notification data={notification} />
        <div className={styles.cardHeader}>
          <span className={styles.title}>Filter</span>
          <div className={styles.cardOptionsWrapper}>
            <CardOptions options={options} />
          </div>
        </div>
        <div className={styles.cardDropDowns}>
          <DatePicker action={handleDateChange} />
        </div>
        <div className={styles.tableWrapper}>
          <LoadingAnim loading={loading} />
          <Table
            tableData={tableData}
            setTableData={setTableData}
            editRow={editRow}
            setEditRow={setEditRow}
            Filters={
              <Filters
                handleDateChange={handleEditDateChange}
                handleBookChange={handleBookChange}
                handleChapterChange={handleChapterChange}
                handleVerseChange={handleVerseChange}
                editRow={editRow}
              />
            }
            handleEditText={handleEditText}
            handleOnSave={handleOnSave}
            handleOnDelete={handleOnDelete}
          />
        </div>
      </div>
      <Pagination
        tableOptions={tableOptions}
        countTotal={countTotal}
        setTableOptions={setTableOptions}
      />
    </>
  );
}
export function Filters({
  handleDateChange,
  editRow,
  handleBookChange,
  handleChapterChange,
  handleVerseChange,
}) {
  return (
    <>
      <DatePicker action={handleDateChange} />
      <ShortInput
        defaultValue={editRow?.book}
        placeholder="Book"
        icon={bookIcon}
        action={handleBookChange}
      />
      <ShortInput
        defaultValue={editRow?.verse}
        placeholder="Verse"
        type="number"
        icon={verseIcon}
        action={handleVerseChange}
      />
      <ShortInput
        defaultValue={editRow?.chapter}
        placeholder="Chapter"
        type="number"
        icon={chapterIcon}
        action={handleChapterChange}
      />
    </>
  );
}
