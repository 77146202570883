import config from "../config/config";
import axios from "axios";

const getToken = () => {
  let token = localStorage.getItem("token");
  if (!token) {
    token = sessionStorage.getItem("token");
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const bibleVerse = {
  getBibleVerse: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}bible/page`, data);
      resolve(response.data);
    });
  },
  addBibleVerse: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}bible`, data);
      resolve(response.data);
    });
  },
  updateQuote: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.put(`${config.serverUrl}bible`, data);
      console.log(response);
      resolve(response.data);
    });
  },
  deleteQuote: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      console.log(data);
      let response = await axios.delete(`${config.serverUrl}bible`, {
        data: data,
      });
      console.log({ data: data });
      resolve(response.data);
    });
  },
  deleteBulk: (data) => {
    getToken();
    return new Promise(async (resolve) => {
      let response = await axios.delete(`${config.serverUrl}bible/bulk`, {
        data: { data: data },
      });
      console.log({ data: data });
      resolve(response.data);
    });
  },
  bulkUpload: (data) => {
    return new Promise(async (resolve) => {
      let response = await axios.post(`${config.serverUrl}bible/bulk`, data);
      resolve(response.data);
    });
  },
};

export default bibleVerse;
