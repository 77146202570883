import React, { useState, useEffect } from "react";
import styles from "../styles/cards/addCard.module.scss";
import _styles from "../styles/cards/pricingCard.module.scss";
import pricingPlans from "../api/pricingPlans";

import ShortInput from "../components/ShortInput";
import TextInput from "../components/TextInput";
import SmallButton from "../components/SmallButton";
import Notification from "../components/Notification";

import closeRed from "../assets/images/closeRed.svg";
import addIcon from "../assets/images/addIcon.svg";
import LoadingAnim from "../components/LoadingAnim";

export default function AddOrRemovePricing({
  updateTrigger,
  setUpdateTrigger,
}) {
  const [notification, setNotification] = useState(null);
  const [editingMonthly, setEditingMonthly] = useState(false);
  const [priceInput, setPriceInput] = useState(null);
  const [previousPriceInput, setPreviousPriceInput] = useState(null);
  const [textInput, setTextInput] = useState(null);
  const [priceIdInput, setPriceIdInput] = useState(null);
  const [validPriceId, setValidPriceId] = useState(false);
  const [open, setOpen] = useState(false);
  const [prices, setPrices] = useState({});
  const [resultId, setResultId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    let res = await pricingPlans.updatePlans([
      { plans: prices, _id: resultId },
    ]);
    if (res) {
      setNotification({
        type: "success",
        text: "Pricing plans updated successfully",
      });
      setLoading(false);
      setUpdateTrigger(!updateTrigger);
      fetchData();
    } else {
      setNotification({
        type: "error",
        text: "Something went wrong",
      });
      setLoading(false);
    }
  };

  const handleAddMonthly = () => {
    setEditingMonthly(true);
    setOpen(true);
  };
  const handleAddyearly = () => {
    setEditingMonthly(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (priceId) => {
    let newMonthly = prices.monthly.filter((item) => item.priceId !== priceId);
    let newYearly = prices.yearly.filter((item) => item.priceId !== priceId);
    setPrices({ ...prices, monthly: newMonthly, yearly: newYearly });
  };
  const handleDoneButton = () => {
    if (!(priceInput && textInput && priceIdInput)) return alert("Please fill all fields");
    if (!validPriceId) return alert("Invalid price ID. It should start with 'prod_'");
    if (editingMonthly) {
      setPrices({
        ...prices,
        monthly: [
          ...prices.monthly,
          {
            id: prices.monthly.length + 1,
            price: priceInput,
            previousPrice: previousPriceInput || priceInput,
            text: textInput,
            priceId: priceIdInput,
          },
        ],
      });
    } else {
      setPrices({
        ...prices,
        yearly: [
          ...prices.yearly,
          {
            id: prices.yearly.length + 1,
            price: priceInput,
            previousPrice: previousPriceInput,
            text: textInput,
            priceId: priceIdInput,
          },
        ],
      });
    }

    setPriceInput(null);
    setTextInput(null);
    setOpen(false);
  };

  const handlePriceInput = (e) => {
    setPriceInput(parseFloat(e.target.value));
  };
  const handlePreviousPriceInput = (e) => {
    setPreviousPriceInput(parseFloat(e.target.value));
  };
  const handleTextInput = (e) => {
    setTextInput(e.target.value);
  };
  const handlePriceIdInput = (e) => {
    setPriceIdInput(e.target.value);
    if (!e.target.value?.trim()?.length > 0) return setValidPriceId(false);
    if (!e.target.value.startsWith("prod_")) return setValidPriceId(false);
    setValidPriceId(true);
  };

  const fetchData = async () => {
    setLoading(true);
    let res = await pricingPlans.getPlans();
    setPrices(res[0].plans);
    setResultId(res[0]._id);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.cardContainer}>
      <LoadingAnim loading={loading}/>
      <Notification data={notification} />
      {open && (
        <div className={_styles.popup}>
          <div className={_styles.popupContent}>
            Add a package
            <button className={_styles.closeButton} onClick={handleClose}>
              <img src={closeRed} alt="" />
            </button>
            <div className={_styles.inputContainer}>
              <ShortInput
                placeholder={"Price (0.99)"}
                type="number"
                action={handlePriceInput}
              />
              <ShortInput
                placeholder={"Previous price (3.99)"}
                type="number"
                action={handlePreviousPriceInput}
              />
              <TextInput
                placeholder={"Package Description"}
                customStyles={{ height: "50px" }}
                onChange={handleTextInput}
              />
              <TextInput
                placeholder={"Price ID (ON STRIPE)"}
                customStyles={{ height: "50px" }}
                onChange={handlePriceIdInput}
              />
            </div>
            <div className={_styles.buttonContainer}>
              <SmallButton
                text={"Done"}
                type="primary"
                onClick={handleDoneButton}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.cardHeader}>
        <span className={styles.title}>Pricing</span>
      </div>
      <div className={_styles.pricingContainer}>
        <div className={_styles.pricingColumn}>
          Monthly
          <button className={_styles.addButton} onClick={handleAddMonthly}>
            <img src={addIcon} alt="" />
          </button>
          <div className={_styles.prices}>
            {prices?.monthly?.map((price) => (
              <div className={_styles.pricingItem}>
                ${price?.price}
                <span className={_styles.description}>{price?.text}</span>
                <button
                  className={_styles.closeButton}
                  onClick={() => handleDelete(price?.priceId)}
                >
                  <img src={closeRed} alt="" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className={_styles.pricingColumn}>
          yearly
          <button className={_styles.addButton} onClick={handleAddyearly}>
            <img src={addIcon} alt="" />
          </button>
          <div className={_styles.prices}>
            {prices?.yearly?.map((price) => (
              <div className={_styles.pricingItem}>
                ${price?.price}
                <span className={_styles.description}>{price?.text}</span>
                <button
                  className={_styles.closeButton}
                  onClick={() => handleDelete(price?.priceId)}
                >
                  <img src={closeRed} alt="" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <SmallButton type="primary" text="Save" onClick={handleSave} />
      </div>
    </div>
  );
}
